import * as React from 'react';
import { PriceComponent } from '@msdyn365-commerce/components';

export default class OrderSummary extends React.Component<any, any> {
    private _renderPrice = (props: any, data: any): JSX.Element | null => {
        const { context } = props;
        if (data.price) {
            return (
                <PriceComponent
                    id={'aaa_b2c_cart'}
                    typeName={'aaa_b2c_cart'}
                    data={data}
                    freePriceText={'free'}
                    originalPriceText={'original price'}
                    currentPriceText={'current price'}
                    context={context}
                />
            );
        }
        return null;
    };

    public render(): JSX.Element | null {
        return (
            <div className={'aaa-order-summary'}>
                <h1 className={'aaa-order-summary-heading'}>Order Summary</h1>
                {this.props.cartItems.map((cartItem: any) => {
                    return (
                        <>
                            <div className={'aaa-order-summary-content'}>
                                <div className={'aaa-order-summary-content-head'}>{cartItem.ProductDetails.Name}</div>
                                {cartItem.rings &&
                                    cartItem.rings.map((ring: any, index: number) => {
                                        return (
                                            <>
                                                <div className={'aaa-order-summary-detail'}>
                                                    <span>
                                                        {ring.ringName} {''} - {''} {ring.alloy}
                                                    </span>
                                                    {cartItem.rings.length - 1 === index && (
                                                        <span className={'aaa-order-summary-price'}>
                                                            {this._renderPrice(this.props, cartItem)}
                                                        </span>
                                                    )}
                                                </div>
                                            </>
                                        );
                                    })}
                            </div>
                        </>
                    );
                })}
                <div className={'aaa-order-summary-button'} onClick={() => this.props.submitOrder()}>
                    <span className={'aaa-summary-total-price'}>{this._renderPrice(this.props, this.props.cartTotalPrice)}</span>
                    <span className={'aaa-summary-submit'}> Submit Order </span>
                </div>
            </div>
        );
    }
}
